@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Praise&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap");

* {
  font-family: "urbanist";
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.FooterSection {
  padding: 5rem 0 5rem 0;
  background-color: #111111;
  background-image: url("../../../assets//vector/footer-back.svg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3rem 1rem 3rem 1rem;
}

.Container {
  max-width: 1440px;
  margin: auto;
  height: 100%;
}

.FooterContent {
  position: relative;
  height: 100%;
  max-width: 1400px;
  margin: auto;
}

.FooterMain {
  height: 100%;
  margin: auto;
  background-size: cover;
  background-position: bottom;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.FooterTop {
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FooterBottom {
  height: 50%;
}

.FooterHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .SocialIcon {
    display: flex;
    gap: 1.6rem;
    color: #ffb23f;
  }
}

.FooterHeadBottom {
  display: flex;
  flex-wrap: wrap;
  .HeadBottomLeft {
    width: 50%;

    p {
      width: 70%;

      color: #ab8750;
      font-size: 18px;
      font-weight: 400;
      text-align: left;
    }
  }

  .HeadBottomRight {
    width: 50%;
  }

  .HeadBottomRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;

    form {
      width: 100%;
    }

    .FormContainer {
      width: 100%;
      background: linear-gradient(
        #ffb23f 100%,
        #ffa723 100%,
        #ffc977 100%,
        #ea9c27 100%
      );
      padding: 0.2rem;
      display: flex;
      gap: 0.2rem;
      border-radius: 2px;
      margin-bottom: 1rem;

      input {
        width: 70%;
        padding: 0.8rem;
        background-color: #111111;
        border: none;
        color: gold;
        outline: none;
      }

      input::placeholder {
        font-size: 20px;
        font-weight: 400;
        color: #623f0b;
        font-family: "urbanist";
        border: none;
      }

      button {
        cursor: pointer;
        width: 29%;
        background-color: #ffb23f;
        color: #623f0b;
        font-size: 22px;
        font-weight: 700;
        border: none;
      }
    }

    .Address {
      display: flex;
      gap: 1rem;
      align-items: center;
      width: 100%;

      p {
        display: flex;
        color: #ab8750;
        margin: 0px;
        flex-direction: column;
        line-height: 2;
      }
    }
    .EmailText {
      width: 100%;
      padding-left: 2.2rem;

      p {
        color: #ffb23f;
        font-weight: 700;
      }
    }
  }
}

.FooterBottom {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%;
  gap: 2rem;
  h1 {
    color: #ffcf87;
    font-size: 32px;
    font-weight: 700px;
    font-family: "urbanist";
  }
}

.ListItems {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  .LeftList {
  }

  .RightList {
  }

  ul {
    display: flex;
    gap: 1.2rem;
    flex-direction: column;
    list-style: none;
    padding: 0px;
    li a {
      color: #ffa51e;
      text-decoration: none;
      font-size: 18px;
      font-weight: 400;
      font-family: "urbanist";
    }
  }
}
.CopyRights {
  height: 52px;
  background-color: black;
  color: #ffa51e;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 16px;
    font-weight: 400;
  }
}

@media screen and (max-width: 1000px) {
  .BrandLogo {
    height: 50px;
  }
  .FooterHead {
    gap: 2rem;
  }
  .FooterHeadBottom {
    flex-direction: column;
    gap: 2rem;
    .HeadBottomLeft {
      width: 100%;
      p {
        width: 100%;
        font-size: 14px;
        text-align: center;
      }
    }
    .HeadBottomRight {
      width: 100%;
      gap: 1rem;

      .FormContainer {
        input {
          width: 70%;
          padding: 0.5rem;
          background-color: #111111;
        }

        input::placeholder {
          font-size: 12px;
          font-weight: 400;
          color: #623f0b;
          font-family: "urbanist";
        }

        button {
          width: 29%;
          background-color: #ffb23f;
          color: #623f0b;
          font-size: 14px;
          font-weight: 700;
          border: none;
        }
      }
    }
    .Address {
      p {
        font-size: 14px;
      }
    }
  }

  .FooterBottom {
    width: 100%;
    h1 {
      font-size: 24px;
    }
  }
  .ListItems {
    gap: 8rem;
    .LeftList {
    }
    .RightList {
    }
    ul {
      li a {
        font-size: 16px;
      }
    }
  }
  .CopyRights {
    p {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 600px) {
  .FooterHead {
    justify-content: center;
    .SocialIcon {
    }
  }
  .ListItems {
    gap: 2rem;
  }
}
