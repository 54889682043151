$primary: #ffa51e;
$secondry: #ffbb55;
.CardContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 49%;
  margin: 2rem 0;
  p {
    text-align: justify;
    font-size: 16px;
  }
  .ImageContainer {
    height: 300px;
    width: 100%;
    overflow: hidden;
    border: 2px solid $primary;
    img {
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }
  }
}
@media screen and (max-width: 1000px) {
  .CardContainer {
    h2 {
      font-size: 22px;
    }
    p {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 768px) {
  .CardContainer {
    width: 90%;
    align-items: center;
  }
}
// ------------------------Content-Card----------------
.ContentCardContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 340px;
  margin: 1rem 0;
  border-radius: 5px;

  p {
    text-align: justify;
    font-size: 16px;
  }
  h2 {
    font-size: 24px;
  }
  .ContentCardImg {
    width: 100%;
    overflow: hidden;
    height: 220px;
    border-radius: 5px;

    img {
      border-radius: 5px;
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in;
    }
    img:hover {
      transform: scale(1.2);
    }
  }
}
@media screen and (max-width: 1000px) {
  .ContentCardContainer {
    h2 {
      font-size: 22px;
    }
    p {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 768px) {
  .ContentCardContainer {
    align-items: center;
  }
}
@media screen and (max-width: 340px) {
  .ContentCardContainer {
    width: 100%;
    padding: 1rem;
    margin: 0;
  }
}
