.ServiceCardContainer {
  font-family: "urbanist";
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding-top: 0.5rem;
  .CardImage {
    width: 100%;
    height: 280px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      transition: all 0.3s ease-in;
    }
  }
  .CardImage:hover img {
    transform: scale(1.1);
  }
  h1 {
    font-size: 32px;
    font-weight: 700;
    color: #ffbb55;
  }
  .CardContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    p {
      width: 70%;
      font-size: 16px;
      font-weight: 400;
      color: #ab8750;
    }
    .CardBtn {
      position: relative;
      width: 30%;
      max-width: 250px;
      height: 52px;
      overflow: hidden;
      button {
        height: 100%;
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        color: #ffb23f;
        background: transparent;
        border: none;
        position: relative;
        z-index: 1;
        border: 2px solid #ffb23f;
        cursor: pointer;
      }
      button:hover {
        color: #111111;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 0;
        height: 100%;
        background-color: #ffb23f;
        transition: width 0.3s ease-in-out; /* Define the transition properties */
        z-index: 0;
      }

      &::before {
        left: 0;
        transform-origin: left;
      }

      &::after {
        right: 0;
        transform-origin: right;
      }

      &:hover::before,
      &:hover::after {
        width: 50%; /* Adjust the width to control the fill on hover */
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .ServiceCardContainer {
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 1.1rem;
    }
    .CardContent {
      align-items: center;
      justify-content: center;
      gap: 2rem;
      p {
        width: 100%;
        font-size: 14px;
        text-align: center;
      }
      .CardBtn {
        width: 100%;
        height: 40px;
        width: 180px;
        button {
          font-size: 1rem;
        }
      }
    }
  }
}
