body {
  background-color: #222222;
}
$primary: #ffa51e;
$secondry: #ffbb55;
h1 {
  color: #ffcf87;
  font-size: 64px;
  font-weight: 300;
}
.HeroSection {
  background-color: #111111;
  background-image: url("../../assets/vector/hero-blur-back.svg");
  height: 480px;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 2rem;
  overflow: hidden;
}

.Container {
  max-width: 1100px;
  margin: auto;
  display: flex;
  position: relative;
  height: 100%;
}
.HeroLeft {
  width: 50%;
  position: absolute;

  left: 0px;
  z-index: 3;
  font-family: "urbanist";
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .bannerLeftContent {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  h2 {
    font-size: 32px;
    font-weight: 400;
    color: $primary;
  }
  h1 {
    font-size: 72px;
    color: $primary;
    font-weight: 400;
    b {
      color: $secondry;
    }
  }
  .BannerBtn {
    height: 52px;
    width: 250px;
    button {
      height: 100%;
      width: 100%;
      font-size: 24px;
      color: #623f0b;
      background-color: $secondry;
      border: 2px solid;
      border-color: #ffb23f;
      font-weight: 700;
      cursor: pointer;
    }
  }
}
.HeroRight {
  width: 50%;
  position: absolute;
  right: 0px;
  z-index: 2;
  .BannerImageInnner {
    position: absolute;
    right: 9rem;
    top: 12rem;
  }
  .BannerImageUpper {
    position: absolute;
    height: 450px;
    right: -20px;
  }
}
@media screen and (max-width: 1000px) {
  .HeroLeft {
    h2 {
      font-size: 25px;
    }
    h1 {
      font-size: 50px;
    }
    .BannerBtn {
      button {
      }
    }
    .bannerLeftContent {
    }
  }

  .HeroRight {
    background-color: #0909096b;
  }
}
@media screen and (max-width: 1100px) {
  .HeroLeft {
    padding: 0 1rem;
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
  }

  .HeroLeft {
    width: 100%;
    align-items: center;
    text-align: center;
    background-color: #00000070;

    h2 {
      font-size: 24px;
    }
    h1 {
      font-size: 52px;
    }
    .BannerBtn {
      height: 42px;
      width: 200px;

      button {
        height: 100%;
        width: 100%;
        font-size: 24px;
        color: #623f0b;
        background-color: $secondry;
        border: 2px solid;
        border-color: #ffb23f;
      }
    }
    .bannerLeftContent {
      align-items: center;
    }
  }

  .HeroRight {
    background-color: #0909096b;
  }
}
@media screen and (max-width: 500px) {
  .HeroLeft {
    background-color: #00000070;
    h2 {
      font-size: 20px;
    }
    h1 {
      font-size: 38px;
    }
    .BannerBtn {
      height: 40px;
      width: 140px;

      button {
        height: 100%;
        width: 100%;
        font-size: 16px;
        color: #623f0b;
        background-color: $secondry;
        border: 2px solid;
        border-color: #ffb23f;
      }
    }
  }
}
// ==================ourclientssection=======================
.OurClientSection {
  padding: 3rem 0 4rem 0;
  .Container {
    flex-direction: column;
    gap: 3rem;
  }
  .Clients {
    .Grid {
      display: flex;
      gap: 5rem;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      img {
        height: 33px;
      }
    }

    .Swiper {
      display: none;
    }
  }
}

// ====================WhoweARESECTION===============
.WhoWeAreSection {
  background-color: #111111;
  background-image: url("../../assets/vector/blur-back.svg");
  background-size: cover;
  background-repeat: no-repeat;

  .Container {
    position: relative;
    padding: 6rem 0 6rem 0;
    .WhoWeAreContent {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      height: 100%;
      p {
        font-size: 24px;
        font-weight: 400;
        color: $primary;
        font-family: "urbanist";
      }
    }
    ::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      border-top: 2px solid $primary; /* Customize border properties as needed */
      width: 30%; /* Adjust the width to control the length of the border */
    }

    ::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid $primary; /* Customize border properties as needed */
      width: 30%; /* Adjust the width to control the length of the border */
    }
  }
}

@media screen and (max-width: 1100px) {
  .WhoWeAreSection {
    .Container {
      padding: 5rem 1rem;
    }
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
  }
  .WhoWeAreSection {
    .Container {
      .WhoWeAreContent {
        text-align: center;
        p {
          font-size: 18px;
        }
      }
    }
  }
}

// ================What we do section======================
.WhatWeDoSection {
  padding: 3rem 1rem 3rem 1rem;

  .WhatWeDoOuterSection {
    max-width: 1100px;
    margin: auto;
    position: relative;
  }
  .ServiceCardsOuter {
    max-width: 1200px;
    margin: auto;
    position: relative;
    top: 0px;
    height: 100%;
    padding: 4rem 2.8rem;

    .RightCorner {
      position: absolute;
      top: 0px;
      right: 0px;
    }
    .Container {
      flex-direction: column;
      gap: 4rem;
    }

    &::before {
      content: "What we do ?";
      position: absolute;

      right: -2.5rem;
      top: 20rem;
      color: goldenrod;
      transform: rotate(90deg);
    }
    &::after {
      content: "";
      position: absolute;
      right: -8.8rem;
      top: 35rem;
      color: goldenrod;
      transform: rotate(90deg);
      width: 300px;
      border-top: 1px solid $secondry;
    }
  }
}

@media (max-width: 768px) {
  .WhatWeDoSection {
    .ServiceCardsOuter {
      padding: 2.9rem 1.5rem;
    }
  }
}
// ==================client responsive  ================
@media (max-width: 768px) {
  .OurClientSection {
    h1 {
      text-align: center;
    }

    .Clients {
      .Grid {
        display: none;
      }

      /* Add this CSS for Swiper display */
      .Swiper {
        display: block;
        img {
          height: 32px;
        }
        .CenterImage {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px;
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .OurClientSection {
    padding: 4rem 1rem;
  }
}
