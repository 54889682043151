.Wrapper {
  background-color: #1e1e1e;
  font-family: "urbanist";
}
$primary: #ffa51e;
$secondry: #ffbb55;
h2 {
  color: $primary;
  font-size: 28px;
}
p {
  color: $secondry;
  font-size: 14px;
  line-height: 1.5;
}
.HeaderSection {
  padding: 8rem 0;
}
.HeaderSection {
  background-color: #111111;
  background-image: url("../../assets/vector/blur-back.svg");
}
.Container {
  max-width: 1100px;
  margin: auto;
  padding: 1rem;
}
.InnerBannerText {
  color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  .BannerText1 {
    font-size: 3rem;
  }
  .BannerText2 {
    font-size: 1rem;
    a {
      color: $secondry;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 900px) {
  .InnerBannerText {
    .BannerText1 {
      font-size: 1.5rem;
    }
    .BannerText2 {
      font-size: 0.8rem;
    }
  }
}
// ============Top-Image-Banner==============
.TopImageContainer {
}

.TopImageContent {
  .TopImage {
    height: 400px;
    img {
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 768px) {
  .TopImageContainer {
  }

  .TopImageContent {
    .TopImage {
      height: 200px;
      img {
        object-fit: cover;
      }
    }
  }
}

.TextContent,
.MainContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1 {
    font-size: 42px;
  }
  p {
    font-size: 18px;
    line-height: 1.6;
    font-weight: 500;
    text-align: justify;
  }

  ul {
    color: $secondry;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 600;
    gap: 1rem;

    padding-left: 2rem;
    line-height: 1.5rem;
  }
}
.MainContent {
  background-image: url("../../assets/vector/blur-back.svg");
  padding: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    font-size: 42px;
  }
  p {
    font-weight: 24px;
  }
  .Container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
article {
  padding: 3rem 0;
}
.CardsContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
@media screen and (max-width: 1100px) {
  .CardsContainer {
    justify-content: center;
  }
}
.TextContent {
  .Container {
    .MajorModule {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .Module {
        ul {
          list-style: none;
          display: flex;
          gap: 1rem;
          flex-wrap: wrap;
          flex-direction: row !important;
          li {
            display: inline;
            color: #111111;
            font-size: 1.5rem;
            padding: 1rem;
            background-color: #ffa51e;
            border-radius: 1rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.MedicalIntelligence {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: $secondry;

    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
  }
}
@media screen and (max-width: 768px) {
  .MedicalIntelligence {
    h1 {
      font-size: 28px;
    }
    p {
      font-size: 18px;
    }
  }
}
.ContactContentContainer {
  .ContactContent {
    display: flex;
    align-items: center;
    .ContentLeft {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    ul {
      padding-left: 0.5rem;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      font-weight: 600;
      gap: 1rem;
      color: $secondry;
    }
  }
}
@media screen and (max-width: 900px) {
  .ContactContent {
    flex-direction: column;
  }
}
