header {
  background-color: #111111;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 9999;
}
.Container {
  max-width: 1100px;
  margin: auto;
}
.NavbarContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .HamburgerMenu {
    display: none;
  }

  nav {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .SocialIcons {
      display: flex;
      justify-content: flex-end;
      gap: 0.8rem;
      padding-right: 1rem;
      color: #c19859;
    }
    ul {
      display: flex;
      list-style: none;
      gap: 1rem;
      li {
        a {
          text-decoration: none;
          font-size: 14px;
          line-height: 16.8px;
          font-weight: 700;
          color: #c19859;
          font-family: "urbanist";
          padding: 1rem;
        }
        a:hover {
          border-bottom: 2px solid goldenrod;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .BrandLogo {
    padding-left: 10px;
  }
}
@media screen and (max-width: 768px) {
  header {
    height: 90px;
    background-color: #0c0b0b;
  }

  .HamburgerMenu {
    padding-right: 10px;
  }
  .BrandLogo img {
    height: 50px;
  }
  .NavbarContent .HamburgerMenu {
    display: block;
    padding-top: 1rem;
  }

  .NavbarContent {
    position: relative;
    nav.Open {
      top: 51px;
      padding: 2rem;
    }
    nav {
      background-image: url("../../../assets/vector/hero-blur-back.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #0d0c0c;
      background-position: bottom;
      position: absolute;
      transition: all 0.3s ease-in-out;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: auto;
      top: -500px;

      .SocialIcons {
        display: none;
      }
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        align-items: center;
        li {
          padding: 1rem;
          width: 100%;
          text-align: center;
        }
      }
      .servicesMenu {
        margin-top: 1rem;
        transition: all 0.3 ease-in-out;
        position: inherit;
      }
    }
  }
}

.active {
  border-bottom: 2px solid goldenrod;
}
// =============stickynavbar======================
.StickyNav {
  height: 52px;
  position: sticky;
  background-color: #1e1e1e;
  top: 0px;
  z-index: 100;
  width: 100%;
  padding: 0px;
  box-shadow: rgba(24, 24, 19, 0.767) 0px 20px 10px -10px;

  .Container {
    width: 100%;
    .NavbarContent {
      padding: 0px;
      height: 52px;
      width: 100%;
      background-color: #1e1e1e;

      .BrandLogo {
        background-color: #1e1e1e;
        width: 70px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(24, 24, 19, 0.767) 0px 20px 10px -10px;

        img {
          height: 50px;
          width: 50px;
        }
      }
      .HamburgerMenu {
        padding: 0 1rem 0 0;
      }
    }
  }
}

/* CSS for the dropdown */
nav ul {
  .dropdown:hover .servicesMenu {
    display: flex;
    gap: 0.6rem;
    flex-direction: column;
  }

  .servicesMenu {
    display: none;
    position: absolute;
    background-color: #111111;
    list-style-type: none;
    padding: 1rem 0 2rem 0;
    margin: 0;
    border-radius: 10px;
  }

  .servicesMenu li {
    padding: 10px;
  }
}
