.Wrapper {
  background-color: #1e1e1e;
}
$primary: #ffa51e;
$secondry: #ffbb55;
* {
  font-family: "urbanist";
}
.HeaderSection {
  padding: 10rem 0;
}
.HeaderSection {
  background-color: #111111;
  background-image: url("../../assets/vector/blur-back.svg");
}
.Container {
  max-width: 1100px;
  margin: auto;
}
.InnerBannerText {
  color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  .BannerText1 {
    font-size: 3rem;
  }
  .BannerText2 {
    font-size: 1rem;
    a {
      color: $secondry;
      text-decoration: none;
    }
  }
}
.MainContainer {
  padding: 4rem 0;
  color: $primary;
  display: flex;
  flex-direction: column;
  gap: 10rem;
  .TopContainer {
    display: flex;

    .AddressCardContainer {
      width: 40%;
      padding: 3rem 1rem;
      height: 200px;
      background-color: #111111;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      gap: 1rem;

      div {
        display: flex;
        gap: 10px;
        align-items: center;
        color: #f7ba54;
      }
      p {
        font-size: 1rem;
        color: $secondry;
      }
    }
  }
  .BottomContainer {
    padding: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .MainContainer {
    .TopContainer {
      flex-direction: column;
      width: 100%;
      gap: 2rem;
      .ContactFormContainer {
        width: 100%;
      }
      .AddressCardContainer {
        width: 100%;
      }
    }
  }
}
